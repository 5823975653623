import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "national-stop-register-read-api"
    }}>{`National Stop Register Read API`}</h1>
    <h2 {...{
      "id": "endpoints"
    }}>{`Endpoints`}</h2>
    <p>{`General purpose read API for the National Stop Register.`}</p>
    <p>{`TariffZones is now deprecated and all consumers should start migrating to FareZones.`}</p>
    <p>{`After T1 - 2025, TariffZones will no longer be updated!`}</p>
    <Swagger url="https://api.entur.io/stop-places/v1/read/openapi.json" mdxType="Swagger" />
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`This API is open under NLOD licence, however, it is required that all consumers identify themselves by using the header ET-Client-Name. Entur will deploy strict rate-limiting policies on API-consumers who do not identify with a header and reserves the right to block unidentified consumers. The structure of ET-Client-Name should be: "company-application"`}</p>
    <p>{`Header examples:
"brakar-journeyplanner"
"fosen_utvikling-departureboard"
"nor_way_bussekspress-nwy-app"`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      